<template>
  <div id="dashboard">
    <div class="page-title px-4">
      <h2>
        <span class="me-2">{{ appName }} Dashboard</span>
      </h2>
    </div>

    <v-divider class="mt-4 mb-10"></v-divider>

    <v-row>
      <v-col cols="12" md="6">
        <v-card class="greeting-card pb-2">
          <v-row class="ma-0 pa-0">
            <v-col v-if="$auth.isAuthenticated" cols="12">
              <v-card-title class="text-no-wrap pt-1 ps-2">
                <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="mr-4" dot>
                  <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
                    <v-img :src="$auth.avatar"></v-img>
                  </v-avatar>
                </v-badge>
                Welcome back, {{ $auth.user.name }}
              </v-card-title>
              <v-card-text class="text-left mt-2 pb-2 ps-2">
                <div>
                  <p class="mb-2">Your profile:</p>
                  <p v-if="$auth.user.name" class="font-weight-semibold primary--text mb-2">
                    <v-icon color="primary" size="18">
                      {{ icons.mdiAccountOutline }}
                    </v-icon>
                    Name: {{ $auth.user.name }}
                  </p>
                  <p class="font-weight-semibold primary--text mb-2">
                    <v-icon color="primary" size="18">
                      {{ icons.mdiEmailBox }}
                    </v-icon>
                    Email: {{ $auth.user.email }}
                  </p>
                </div>
              </v-card-text>

              <v-card-text align="end" class="pb-1">
                <v-btn small color="primary" append-icon="ri-logout-box-r-line" @click="logout">
                  <v-icon :size="16" class="me-2">
                    {{ icons.mdiLogoutVariant }}
                  </v-icon>
                  Logout
                </v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" md="6">
            <v-card class="">
              <v-row class="ma-0 pa-0">
                <v-col cols="12">
                  <p class="text-center text-no-wrap pt-1 text--xs">Total Devices</p>
                  <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                    <div>
                      {{ allDevices.length }}
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card class="">
              <v-row class="ma-0 pa-0">
                <v-col cols="12">
                  <p class="text-center text-no-wrap pt-1 text--xs">Enabled Vehicles</p>
                  <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                    <div>
                      {{ enabledVehicles.length }}
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-card class="">
              <v-row class="ma-0 pa-0">
                <v-col cols="12">
                  <p class="text-center text-no-wrap pt-1 text--xs">Vehicles On Trip</p>
                  <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                    <div>
                      {{ onTripVehicles.length }}
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card class="">
              <v-row class="ma-0 pa-0">
                <v-col cols="12">
                  <p class="text-center text-no-wrap pt-1 text--xs">Open Notes</p>
                  <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                    <div>
                      {{ openNotes.length }}
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiLogoutVariant, mdiViewDashboard, mdiAccountOutline, mdiEmailBox, mdiAccountCowboyHat } from "@mdi/js"
import themeConfig from "@themeConfig"
import { mapState } from "vuex"

export default {
  metaInfo() {
    return {
      title: "Dashboard",
    }
  },

  setup() {
    return {
      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiLogoutVariant,
        mdiViewDashboard,
        mdiAccountOutline,
        mdiEmailBox,
        mdiAccountCowboyHat,
      },
    }
  },

  computed: {
    ...mapState({
      vehicles: state => state.gtfs.vehicles,
      devices: state => state.gtfs.devices,
      notes: state => state.gtfs.notes,
    }),
    allDevices() {
      return this.devices
    },
    openNotes() {
      return this.notes.filter(notice => notice.status === "Open")
    },
    enabledVehicles() {
      return this.vehicles.filter(vehicle => vehicle.config.is_enabled === true)
    },
    onTripVehicles() {
      return this.vehicles.filter(vehicle => vehicle.config.is_enabled && vehicle.status.trip.trip_id !== null)
    },
  },

  methods: {
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
}
</script>
